<template>
    <Modal width="600px" v-model:visible="visible" :mask-closable="false" :footer="false" :closable="false">
        <template v-if="privacy === 1">
            <p class="term-tip">{{ privacyUpdateTip }}</p>
            <p class="term-a">
                <a href="/terms/PrivacyPolicy" target="blank">{{ $t('goToView') }}</a>
            </p>
            <span class="dialog-footer">
                <Button type="primary" @click="agree('privacy')">{{ $t('agree') }}</Button>
                <Button @click="disagree">{{ $t('disagree') }}</Button>
            </span>
        </template>
        <template v-else-if="eula === 1">
            <p class="term-tip">{{ eulaUpdateTip }}</p>
            <p class="term-a">
                <a href="/terms/EULA" target="blank">{{ $t('goToView') }}</a>
            </p>
            <span class="dialog-footer">
                <Button type="primary" @click="agree('eula')">{{ $t('agree') }}</Button>
                <Button @click="disagree">{{ $t('disagree') }}</Button>
            </span>
        </template>
        <template v-else-if="privacy === 0 || eula === 0">
            <p v-if="privacy === 0" class="term-tip">{{ privacyUpdateTip }}</p>
            <p v-else class="term-tip">{{ eulaUpdateTip }}</p>
            <p class="term-a">
                <span @click="agree(privacy === 0 ? 'privacy' : 'eula')">
                    <a href="/terms/PrivacyPolicy" target="blank" v-if="privacy === 0">{{ $t('goToView') }}</a>
                    <a href="/terms/EULA" target="blank" v-else>{{ $t('goToView') }}</a>
                </span>
            </p>
            <span class="dialog-footer">
                <Button @click="agree(privacy === 0 ? 'privacy' : 'eula')">{{ $t('close') }}</Button>
            </span>
        </template>
    </Modal>
</template>

<script>
import { defineComponent, ref, watch, inject, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default defineComponent({
    name: '',
    props: {
        privacyUpdate: {
            type: Number,
            default: 2
        },
        eulaUpdateTip: {
            type: String
        },
        privacyUpdateTip: {
            type: String
        },
        eulaUpdate: {
            type: Number,
            default: 2
        },
        email: {
            type: String
        }
    },
    setup(props, ctx) {
        const t = inject('$t')
        const http = inject('$http')
        const msg = inject('$message')
        const store = useStore()
        const router = useRouter()
        const privacy = ref(2)
        const eula = ref(2)
        const loading = ref(false)
        watch(
            () => props.privacyUpdate,
            (val) => {
                privacy.value = val
            }
        )
        watch(
            () => props.eulaUpdate,
            (val) => {
                eula.value = val
            }
        )
        const visible = computed(() => privacy.value !== 2 || eula.value !== 2)
        const agree = (type) => {
            const id = type === 'eula' ? 1 : 2
            http.account.agree(id).then(({ code, message }) => {
                if (code !== 0) {
                    msg.error(message)
                    return
                }
                type === 'eula' ? (eula.value = 2) : (privacy.value = 2)
                if (eula.value === 2 && privacy.value === 2) {
                    ctx.emit('agree')
                }
            })
        }
        const disagree = () => {
            eula.value = 2
            privacy.value = 2
            store.commit('clearUserData')
            ctx.emit('disagree')
            router.push('/login')
        }
        return {
            disagree,
            agree,
            privacy,
            eula,
            visible,
            loading,
            t
        }
    }
})
</script>

<style lang="less" scoped>
.term-tip {
    text-align: center;
    font-size: 18px;
    color: #333333;
    line-height: 28px;
    margin-top: 40px;
}
.term-a {
    text-align: center;
    padding: 16px;
    a {
        text-decoration: underline;
    }
}
.dialog-footer {
    text-align: center;
    display: block;
    button {
        margin: 10px 40px;
    }
}
</style>
