<template>
    <div class="login-container">
        <Lang class="lang" />
        <div class="left" />
        <div class="right">
            <div class="admin-login-main">
                <h2 class="admin-login-title"></h2>
                <div class="login-account">
                    <span :class="{ active: formData.from === 'email' }" @click="switchAccount('email')"> {{ t('login.sysLogin') }}</span>
                    <span :class="{ active: formData.from === 'info' }" @click="switchAccount('info')"> {{ t('login.infoLogin') }}</span>
                </div>
                <Form ref="formDom" :model="formData" :rules="formRule" :wrapper-col-props="{ span: 24 }" @keyup.enter="handleSubmit" autocomplete="off">
                    <FormItem field="username" hide-asterisk :validate-trigger="['blur']">
                        <Input
                            v-model.trim="formData.username"
                            spellcheck="false"
                            :placeholder="formData.from === 'email' ? t('login.username') : t('login.infoName')" />
                    </FormItem>
                    <FormItem field="password" hide-asterisk :validate-trigger="['blur']">
                        <InputPassword v-model.trim="formData.password" spellcheck="false" :placeholder="t('login.password')" />
                    </FormItem>
                    <FormItem field="captcha" hide-asterisk :validate-trigger="['blur']">
                        <Input class="code-input" v-model.trim="formData.captcha" spellcheck="false" :placeholder="t('login.code')" />
                        <Image :preview="false" height="32" width="100" :src="captcha" alt="" @click="getCaptcha" />
                    </FormItem>
                    <Button class="login-btn btn-fill" type="primary" @click="handleSubmit">
                        {{ t('login.login') }}
                    </Button>
                    <div class="link-box">
                        <a @click="router.push('/forgetPassword')" v-if="formData.from === 'email'">{{ t('login.forgetPwd') }}</a>
                        <a href="https://info.paxsz.com/GetBackPassword.aspx" v-else>{{ t('login.forgetPwd') }}</a>
                    </div>
                </Form>
            </div>
        </div>
        <Footer />
        <TermModal
            :email="formData.username"
            :eulaUpdate="eulaUpdate"
            :privacyUpdate="privacyUpdate"
            :eulaUpdateTip="eulaUpdateTip"
            :privacyUpdateTip="privacyUpdateTip"
            @agree="initData(loginData)"
            @disagree=";(eulaUpdate = 2), (privacyUpdate = 2)" />
    </div>
</template>

<script>
import { defineComponent, reactive, inject, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Form } from '@arco-design/web-vue'
import { useStore } from 'vuex'
import Lang from '@/components/Lang.vue'
import TermModal from '@/components/TermModal.vue'
import Footer from '@/views/layout/Footer.vue'
import Sha256 from 'crypto-js/sha256'
export default defineComponent({
    components: {
        Footer,
        Lang,
        TermModal
    },
    setup() {
        const http = inject('$http')
        const t = inject('$t')
        const formDom = ref(null || Form)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const loginData = ref()
        const eulaUpdate = ref()
        const eulaUpdateTip = ref()
        const privacyUpdate = ref()
        const privacyUpdateTip = ref()

        const captcha = ref('')
        const getCaptcha = () => {
            http.account.getCaptcha().then(({ data }) => {
                captcha.value = `data:image/jpeg;base64,${data.image}`
                formData.captchaId = data.id
            })
        }
        getCaptcha()

        const formData = reactive({
            username: '',
            password: '',
            captcha: '',
            captchaId: '',
            from: route.query.from || 'email'
        })
        const formRule = {
            username: [
                {
                    validator: (val, callback) => {
                        if (!val) {
                            const tip =
                                formData.from === 'info' ? t('tip.emptyTip', { key: t('login.infoName') }) : t('tip.emptyTip', { key: t('login.username') })
                            callback(tip)
                        }
                        callback()
                    }
                }
            ],
            password: [{ required: true, message: t('tip.emptyTip', { key: t('login.password') }) }],
            captcha: [{ required: true, message: t('tip.emptyTip', { key: t('login.code') }) }]
        }
        const initData = (data) => {
            const { accessToken, refreshToken, roleEntity, teamId, username } = data
            store.commit('setAccessToken', accessToken)
            store.commit('setRefreshToken', refreshToken)
            store.commit('setUserData', { role: roleEntity.name, teamId: teamId, username, from: formData.from })
            router.push('/')
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    const param = { ...formData }
                    if (formData.from !== 'info') {
                        param.password = Sha256(param.password).toString()
                    }
                    http.account
                        .login(param)
                        .then(({ data }) => {
                            if (data.eulaUpdate === 2 && data.privacyUpdate === 2) {
                                initData(data)
                            } else {
                                store.commit('setAccessToken', data.accessToken)
                                store.commit('setRefreshToken', data.refreshToken)
                                loginData.value = data
                                eulaUpdate.value = data.eulaUpdate
                                eulaUpdateTip.value = data.eulaContent
                                privacyUpdate.value = data.privacyUpdate
                                privacyUpdateTip.value = data.privacyContent
                            }
                        })
                        .catch(() => {
                            getCaptcha()
                        })
                }
            })
        }

        const switchAccount = (from) => {
            formData.from = from
            formDom.value.resetFields()
        }

        return {
            t,
            formData,
            formDom,
            formRule,
            captcha,
            router,
            eulaUpdate,
            eulaUpdateTip,
            privacyUpdate,
            privacyUpdateTip,
            loginData,
            getCaptcha,
            handleSubmit,
            switchAccount,
            initData
        }
    }
})
</script>

<style lang="less" scope>
.login-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    .lang {
        position: absolute;
        right: 8%;
        top: 50px;
        z-index: 1;
    }
    .left {
        width: 60%;
        height: 100%;
        display: inline-block;
        background: url('~@/assets/img/login_bg.png') no-repeat center center;
        background-size: contain;
    }
    .arco-icon-hover {
        font-size: 18px;
    }
    .right {
        position: relative;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .admin-login-main {
            width: 400px;
            padding: 40px 50px;
            background: #ffffff;
            box-shadow: 0px 0px 15px -10px #999;
            border-radius: 10px;
            .admin-login-title {
                height: 36px;
                width: 100%;
                margin-top: 20px;
                margin-bottom: 30px;
                background-size: contain;
                background-image: url('~@/assets/img/logo.png');
                background-repeat: no-repeat;
                background-position: center;
            }
            .code-input {
                margin-right: 10px;
            }
            .arco-image-img {
                cursor: pointer;
            }
            .login-btn {
                margin: 20px 0;
                width: 100%;
                height: 40px;
                font-size: 18px;
            }
            .arco-input-wrapper {
                border: 0px !important;
                border-radius: 0;
                border-bottom: 1px solid #999 !important;
            }
        }
        .link-box {
            text-align: right;
            font-size: 14px;
            margin: 10px 10px 0;
            a {
                color: #4e80f4;
                text-decoration: none;
            }
        }
        .login-account {
            margin-bottom: 18px;
            color: #2b32c9;
            font-size: 16px;
            cursor: pointer;
            display: flex;
            justify-content: space-around;
            span {
                padding-bottom: 8px;
                display: inline-block;
                color: #666666;
                &.active {
                    color: #2bc954;
                    border-bottom: 1px solid #2bc954;
                    font-weight: bold;
                }
            }
        }
    }
}
@media screen and (max-width: 940px) {
    .login-container {
        display: block;
        .left {
            width: 100%;
            filter: blur(10px);
        }
        .right {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -200px;
            margin-left: -180px;
        }
    }
}
</style>
